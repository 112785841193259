<template>
  <v-container>
    <h1>{{ verb }} Institute</h1>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.name"
              :counter="30"
              :disabled="loading"
              :rules="rules.name"
              label="Name"
              required
            ></v-text-field>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Institute from "@/services/institute";

export default {
  name: "Institute.Form",
  data() {
    return {
      valid: false,
      form: {
        name: ""
      },
      rules: {
        name: [
          v => !!v || "Name is required",
          v =>
            (v.length >= 5 && v.length <= 30) ||
            "Name must be greater than 5 and less then 30",
          v =>
            new RegExp(/^[\w\-\s]+$/i).test(v) ||
            "Name must only contain alphanumeric and whitespace characters"
        ]
      },
      loading: false
    };
  },
  computed: {
    verb() {
      return this.$route.params.id ? "Update" : "Create";
    },
    pastVerb() {
      return `${this.verb}d`;
    },
    dataId() {
      return this.$route.params.id;
    }
  },
  async created() {
    this.setNavbarTitle("Manage Institute");
    if (this.dataId) {
      const institute = await Institute.find(this.dataId);
      const { name } = institute.data.data;
      this.form = { name };
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          if (this.dataId) {
            await Institute.update(this.dataId, this.form);
          } else {
            await Institute.create(this.form);
          }

          this.$snackbar.showMessage({
            content: `Data ${this.pastVerb.toLowerCase()}`,
            variant: "success"
          });
          this.$router.push({ name: "Institute.Index" });
        } catch (error) {
          this.loading = false;
        }
      }
    }
  }
};
</script>
